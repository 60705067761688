.login {
  padding-top: 25vh;
  color: black;
  height: 100vh;
}

.password-error {
  color: red;
  font-size: 16px;
  padding-top: 24px;
  padding-bottom: 16px;
  position: absolute;
  margin-left: 44px;
}

.password-protected {
  font-family: "Roboto-bold";
  margin-top: 18px;
  font-size: 20px;
}

.password-input {
  margin-top: 57px;
  width: 300px !important;
  height: 48px !important;
}

.login-button {
  padding: 10px !important;
  font-size: 18px !important;
}

.sidebar {
  padding: 0;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 20%;
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
  border-right: 1px solid #e2e5eb;
  transition: 0.1s ease-in;
}

.collapsed {
  min-width: 48px;
  transition: 0.1s ease-in;
}

.logo-zone {
  height: 60px;
}

.menu {
  margin: 0 12px;
}

.text-selected {
  color: #0750e2 !important;
}

.sidebar-menu-item {
  text-decoration: none;
  color: black;
  display: block;
  height: 48px;
  font-size: 14px;
  padding: 12px 20px;
}

.sidebar-menu-item:hover {
  text-decoration: none;
}

.logout-area {
  padding: 0 12px;
}

.collapse-button {
  position: absolute;
  background: transparent;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #273b4e1a;
  left: 100%;
  top: 45px;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.1s ease-in-out;
}

.rotate-button {
  transform: rotate(180deg) translateX(10px);
}

.table {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.widgets {
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  padding: 12px;
}

.widget-title {
  font-size: 16px;
  color: #8494a4;
  font-weight: 500;
}

.milestone {
  height: 16px;
  width: 16px;
  position: absolute;
  z-index: 2;
  background-color: #e2e5eb;
  border: 1px solid #0750e2;
  border-radius: 50%;
  /* margin-top: 3px; */
}

.timeline-component {
  margin-left: 7px;
  padding-left: 30px;
  border-left: 3px solid #f0f2f5;
}

.timeline-component-title {
  color: #8494a4;
  font-family: Roboto-Bold;
  font-size: 15px;
  line-height: 16px;
}

.displayBox {
  height: 250px;
  overflow-y: scroll;
}

.table-limit {
  font-size: 12px;
}

.non-editable {
  background-color: #fdfdfd;
  height: 60vh;
  overflow-y: scroll;
}

.btn-disabled {
  background-color: #d8d9db !important;
  color: #a8a8a8 !important;
}

.message-queue {
  position: absolute;
  bottom: 0;
  width: 40%;
}

.snackbar-green {
  border-left: 4px solid #2ad130 !important;
  background-color: #edffee !important;
}

.snackbar-red {
  border-left: 4px solid #d1302a !important;
  background-color: #ffeeed !important;
}

.cross {
  font-size: 16px;
  margin-right: 12px;
  line-height: 0.5;
  cursor: pointer;
}

.text--red {
  color: #e52323 !important;
}

.text--green {
  color: #01a214 !important;
}

.result-text {
  color: #273b4e;
  font-size: 16px;
  margin: 24px 0;
}
