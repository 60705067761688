@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}
