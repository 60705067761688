h1 {
  font-family: "Roboto-bold";
  font-weight: 800 !important;
  font-size: 24px !important;
  color: #273b4e;
  margin: 40px 16px 32px !important;
}

a {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.796) !important;
}

select {
  width: 70px;
  padding: 4px 10px !important;
  font-size: 14px !important;
  border: 1px solid #e2e5eb !important;
  border-radius: 4px;
  background: url(./assets/misc/caretDown.png) no-repeat right #fff;
  background-size: 25px 25px;
  -webkit-appearance: none;
  background-position-x: 40px;
  cursor: pointer;
}

textarea {
  resize: none !important;
}

span {
  font-size: 14px;
}

p {
  font-size: 16px !important;
}
